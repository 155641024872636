import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import classes from './Home.module.css'
import playIcon from '../../assets/images/playIcon.png'
import lineImg from '../../assets/images/Line.png'
import icon1 from '../../assets/images/icons1.png'
import icon2 from '../../assets/images/icons2.png'
import icon3 from '../../assets/images/icons3.png'
import icon4 from '../../assets/images/icons4.png'
import arrow from '../../assets/images/arrow.png'

const Home = () => {
    const [imageIndex, setImageIndex] = useState(0);
    const [imageIndex2, setImageIndex2] = useState(0);
    const [fade, setFade] = useState(false);
    const pictures = [
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2486_rfezk0.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/Next_Circle_Logo_page-0002_fy510l.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2505_cuuq2o.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2411_1_m19yqj.jpg',
    ]

    const pictures2 = [
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2418_1_qqp108.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/group-happy-african-volunteers-put-hands-hands-park-africa-volunteering-charity-people-ecology-concept_627829-329_qpbrzw.avif',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2469_gtdiwp.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/TLS-Skills-Acquisition-Indispensable-for-Jobs-Creation_xsb1od.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/AV-Skill-Acquistion-2_qfssoq.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/Skills-acquisition-centre_vttjuh.jpg'
    ]
    const pictures3 = [
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2411_jqf8qp.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/couturieres-INTERHOLCO_kyhkmg.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/africa-humanitarian-aid-doctor-taking-care-patient_nysqe1.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2468_xbdfci.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/Rullion-Capacity-Builders_vcotex.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/Skill-Aquisition_gcttel.jpg'
    ]
    const text = [
        'Eliminating Destitution & Recidivism by Creating Opportunities for Economic Productivity',
        ' ',
        'A SAFE SOCEITY WITH LESS CRIME AND MORE PRODUCTIVITY',
        'To Create a Better World with Endless Opportunities for Absolution, Growth and Functionality'
    ]
    const color = [
        '#e8ebf0',
        'black',
        '#e8ebf0',
        '#e8ebf0',

    ]

    const show = [
        'flex',
        'none',
        'flex',
        'flex',
    ]
    const doDonate = () => {
        const mailtoLink = 'mailto:info@nextcircle.org?subject=I%20Want%20To%20Donate';
        window.location.href = mailtoLink;
    }
    const doVolunteer = () => {
        const mailtoLink = 'mailto:info@nextcircle.org?subject=I%20Want%20To%20Volunteer';
        window.location.href = mailtoLink;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(true); // Apply fade-out class
            setTimeout(() => {
                setImageIndex((prevIndex) => (prevIndex + 1) % pictures.length);
                setImageIndex2((prevIndex) => (prevIndex + 1) % pictures2.length);
                setFade(false);
            }, 1000);
        }, 5000);
        return () => clearInterval(intervalId);
    }, [pictures.length, pictures2.length]);

    const [isLoading, setIsLoading] = useState(true);
    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        await Promise.all(promises)
        setIsLoading(false)
    }
    useEffect(() => {
        const imgs = [
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2486_rfezk0.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Next_Circle_Logo_page-0002_fy510l.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2505_cuuq2o.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2411_1_m19yqj.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2418_1_qqp108.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/group-happy-african-volunteers-put-hands-hands-park-africa-volunteering-charity-people-ecology-concept_627829-329_qpbrzw.avif',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2469_gtdiwp.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/TLS-Skills-Acquisition-Indispensable-for-Jobs-Creation_xsb1od.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/AV-Skill-Acquistion-2_qfssoq.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Skills-acquisition-centre_vttjuh.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2411_jqf8qp.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/couturieres-INTERHOLCO_kyhkmg.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/africa-humanitarian-aid-doctor-taking-care-patient_nysqe1.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_DSC2468_xbdfci.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Rullion-Capacity-Builders_vcotex.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Skill-Aquisition_gcttel.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/volunteer_bggugj.png'
        ]
        cacheImages(imgs)
    }, []);
    return (
        <>
            <Layout>

                {isLoading ?
                    <div id="spinner" className={`container`}>
                        <div className={`loading`}></div>
                    </div>
                    :
                    <div className={classes.Home}  >
                        <section >
                            <div
                                className={`${classes.hero} ${classes.fadeInOut} ${fade ? classes.fadeOut : ''}`}
                                style={{ backgroundImage: `url(${pictures[imageIndex]})` }}>
                                <div className={classes.heroContent}>
                                    <div className={classes.heroContentText}>
                                        <h1
                                            style={{ color: color[imageIndex] }}
                                        >
                                            {text[imageIndex]}
                                        </h1>
                                    </div>
                                    <div className={classes.HeroBtn}
                                        style={{ display: `${show[imageIndex]}` }}
                                    >
                                        <button>What we do</button>
                                        <div className={classes.HeroPlayBtn}>
                                            <img src={playIcon} alt="nextcircle" />
                                            <h1
                                                style={{ color: color[imageIndex] }}
                                            >Watch Video</h1>
                                        </div>
                                    </div>
                                    <div className={classes.HeroDetails}>
                                        <h1
                                            style={{ color: color[imageIndex], display: `${show[imageIndex]}` }}
                                        >
                                            230+ youth currently under our vocational training and empowerment program</h1>
                                        <hr className={classes.line} />
                                        <h2
                                            style={{ color: color[imageIndex] }}
                                        >
                                            {/* 50 donations collected */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={classes.KnowUs}>
                            <h1 className={classes.CompanyName}>NEXT CIRCLE RELIEF AND REHABILTITATION FOUNDATION</h1>
                            <div className={classes.KnowUsLeft} >
                                <div className={classes.KnowUsHead}>
                                    <img src={lineImg} alt="nextcircle" />
                                    <h1>know about us</h1>
                                </div>
                                <div className={classes.KnowUsText}>
                                    <h2>
                                        Empowering lives through
                                        mental health support,
                                        vocational skill training,
                                        and financial aid.
                                    </h2>
                                </div>
                                <div className={classes.KnowUsDesc}>
                                    <h3>
                                        Next Circle is a Non Governmental
                                        Organization focused on creating
                                        economic impact by providing opportunities
                                        to reduce destitution and crime rate, by
                                        supporting the productivity of the next
                                        generation, and providing aid to displaced
                                        elderly citizens. Our main pivot are victims
                                        of violent/ economic crimes, formerly convicted
                                        individuals, and destitute youth whom we provide
                                        support to heal from trauma and begin a new life
                                        via empowerment programs. The main goals of Next
                                        Circle Relief and Rehabilitation Foundation
                                        are to provide mental health assistance,
                                        vocational skill training, rehabilitation,
                                        and financial support through various job
                                        creating establishments, in order to reinstate
                                        these individuals into socio-economic productivity.
                                        We hope to therefore reduce crime rate by tackling
                                        unemployment, destitution, recidivism and depression.
                                    </h3>
                                    <button>
                                        Learn More
                                    </button>
                                </div>
                            </div>
                            <div
                                className={`${classes.KnowUsRight} ${classes.fadeInOut} ${fade ? classes.fadeOut : ''}`}
                            >
                                <div className={classes.KnowUsRightImg}>
                                    <img src={pictures2[imageIndex2]} alt="nextcircle" />
                                </div>
                            </div>
                        </section>
                        <div id='whatwedo'>
                            <section className={`${classes.KnowUs} ${classes.KnowUsForced}`}>
                                <div className={`${classes.KnowUsLeft} ${classes.KnowUsLeftForce}`} >
                                    <div className={classes.KnowUsHead}>
                                        <img src={lineImg} alt="nextcircle" />
                                        <h1>what we do</h1>
                                    </div>
                                    <div className={classes.KnowUsText}>
                                        <h2>
                                            Some services we <br />provide for our targeted communities
                                        </h2>
                                        <p>Offering a range of essential
                                            services tailored to meet the
                                            distinct needs of our targeted communities
                                        </p>
                                    </div>
                                    <div className={classes.KnowUsPoints}>
                                        <div className={classes.KnowUsPoint}>
                                            <img src={icon1} alt="nextcircle" />
                                            <div>
                                                <h1>
                                                    Mental Health Assistance
                                                </h1>
                                                <h2>
                                                    Our organization's main goals
                                                    include addressing mental
                                                    health challenges due to
                                                    traumatic experiences and
                                                    it's effect on lifestyle, productivity,
                                                    and social connections of affected persons.
                                                    Thereby providing support with effective
                                                    reintegration into socio-economic productivity.
                                                </h2>
                                            </div>
                                        </div>
                                        <div className={classes.KnowUsPoint}>
                                            <img src={icon2} alt="nextcircle" />
                                            <div>
                                                <h1>
                                                    Counselling
                                                </h1>
                                                <h2>
                                                    We provide comprehensive
                                                    counselling & support programs
                                                    designed to help individuals
                                                    heal from trauma and embark
                                                    on a new life, focusing on
                                                    empowerment and personal growth.
                                                </h2>
                                            </div>
                                        </div>
                                        <div className={classes.KnowUsPoint}>
                                            <img src={icon3} alt="nextcircle" />
                                            <div>
                                                <h1>
                                                    Rehabilitation
                                                </h1>
                                                <h2>
                                                    Key factors such as unemployment,
                                                    destitution, recidivism (repeat offenses)
                                                    keep a sect of individuals in an unending
                                                    circle of offence and reproach. We aim to
                                                    contribute significantly to reducing the
                                                    overall crime rate in the communities we
                                                    serve by ensuring that intending individuals
                                                    are provided with the knowledge, skills, and
                                                    equipment to become gainfully employed and
                                                    ready to cohabit with other well meaning
                                                    members of the society.
                                                </h2>
                                            </div>
                                        </div>
                                        <div className={classes.KnowUsPoint}>
                                            <img src={icon4} alt="nextcircle" />
                                            <div>
                                                <h1>
                                                    Vocational Skill Training
                                                </h1>
                                                <h2>
                                                    Next Circle primarily extends
                                                    support to unemployed and
                                                    unemployable individuals.
                                                    We provide them with the knowledge,
                                                    skill and practical experience needed
                                                    to effectively start an SME, or gain
                                                    employment in already established businesses.
                                                    Our volunteers also continue to mentor numerous
                                                    beneficiaries to ensure success, consistency
                                                    and continuous learning
                                                </h2>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div
                                    className={`${classes.KnowUsRight} ${classes.fadeInOut} ${fade ? classes.fadeOut : ''}`}
                                >

                                    <div className={classes.KnowUsRightImg}>
                                        <img src={pictures3[imageIndex2]} alt="nextcircle" />
                                    </div>
                                </div>
                            </section>
                        </div>
                        
                        <section className={classes.Supporters}>
                            <h1>our supporters</h1>
                            <hr className={classes.line} />
                            <div className={classes.SupportersIcons}>
                                <div className={classes.IconDiv}>
                                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1703086441/fyreflyz_nvo9i4.jpg' alt='' />
                                    {/* <p>Fyreflyz Events & <br /> Pyrotechnics Ltd.</p> */}
                                </div>
                                <div className={classes.IconDiv}>
                                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1703086441/annie_f8g9fe.jpg' alt='' />
                                    {/* <p>Annie's Gallery</p> */}
                                </div>
                                <div className={classes.IconDiv}>
                                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1703086441/wheelsgong_bakcs9.jpg' alt='' />
                                    {/* <p>Wheels Gong LLC</p> */}
                                </div>
                                <div className={classes.IconDiv}>
                                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1703086440/valerana_brkzgu.jpg' alt='' />
                                    {/* <p>Val Arena & McMuffins</p> */}
                                </div>
                            </div>
                        </section>
                        <section className={classes.Contributions}>
                            <div className={classes.Contributionscard}>
                                <div className={classes.ContributionscardText}>
                                    <h1>
                                        We empower marginalized individuals fostering economic productivity and community reintegration
                                    </h1>
                                    <div className={classes.Contributionsbtn}>
                                        <button onClick={doVolunteer} className={classes.Contributionsbtn1}>Join as a Volunteer</button>
                                        <button onClick={doDonate} className={classes.Contributionsbtn2}>Donate</button>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className={classes.Events}>
                            <h1 className={classes.OurEvent}>Our Events</h1>
                            <div className={classes.eventsection}>
                                <div className={classes.eventcard}>
                                    <div className={classes.card1}>
                                        <h1>13</h1>
                                        <h2>December</h2>
                                        <h3>2023</h3>
                                    </div>
                                    <div className={classes.card2}>
                                        <h1>
                                            PAST EVENTS
                                        </h1>
                                        <h2>
                                            Topic: Character Building Campaign
                                        </h2>
                                        <h3>
                                            Venue: Calvary International School
                                        </h3>
                                    </div>
                                    <div className={classes.card3}>
                                        <img src={arrow} style={{ transform: 'rotate(180deg)' }} alt='' />
                                    </div>
                                </div>
                                <div className={classes.eventcard}>
                                    <div className={classes.card1}>
                                        <h1>30</h1>
                                        <h2>April</h2>
                                        <h3>2024</h3>
                                    </div>
                                    <div className={classes.card2}>
                                        <h1>
                                            UPCOMING EVENTS
                                        </h1>
                                        <h2>
                                            Topic: Character Building Campaign
                                        </h2>
                                        <h3>
                                            Venue: Funktob Group Of Schools
                                        </h3>
                                    </div>
                                    <div className={classes.card3}>
                                        <img src={arrow} alt='' />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>}
            </Layout>
        </>
    )
}

export default Home
