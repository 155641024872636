import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../Layout/Layout';
import classes from './AboutUs.module.css'
import line from '../../assets/images/Line.png'
import { send } from '@emailjs/browser';
import * as data from '../../data'



const AboutUs = () => {
    const [imageIndex, setImageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const countries = data.country

    const [fade, setFade] = useState(false);
    const pictures = [
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_qqDSC2480_b7h6yn.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/qqqq_DSC2461_zn6xw7.jpg',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/qq_DSC2428_m78fnw.jpg'
    ]
    const [isLoading, setIsLoading] = useState(true);
    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        await Promise.all(promises)
        setIsLoading(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        const imgs = [
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/_qqDSC2480_b7h6yn.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/qqqq_DSC2461_zn6xw7.jpg',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/qq_DSC2428_m78fnw.jpg'
        ]
        cacheImages(imgs)
    }, []);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(true); // Apply fade-out class
            setTimeout(() => {
                setImageIndex((prevIndex) => (prevIndex + 1) % pictures.length);
                setFade(false);
            }, 1000);
        }, 5000);
        return () => clearInterval(intervalId);
    }, [pictures.length]);

    const [toSend, setToSend] = useState({
        first_name: '',
        last_name: '',
        email: '',
        subject_title: '',
        phone_number: '',
        subject_title_other: '',
        message: '',
        countrySelect: '',
        dialCode: '',

    });
    const onSubmit = (e) => {
        setLoading(true);
        console.log(toSend, e)
        e.preventDefault();
        send('service_d2h5nxp', 'template_p7ginyo', toSend, 'hvvdkaRq_KZK-Sz2_')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setLoading(false);
                alert('email sent');
            })
            .catch((err) => {
                console.log('FAILED...', err);
                setLoading(false);

            });
    };


    const handleChange = (e) => {
        const { name, value } = e?.target;
        if (name === 'countrySelect') {
            const selectedCountryCode = value;
            const selectedCountryData = countries.find(country => country.code === selectedCountryCode);
            if (selectedCountryData) {
                setToSend({
                    ...toSend,
                    [name]: selectedCountryData.name,
                    dialCode: selectedCountryData.dial_code,
                });
            } else {
                setToSend({
                    ...toSend,
                    [name]: '',
                    dialCode: '',
                });
            }
        } else {
            setToSend({
                ...toSend,
                [name]: value,
            });
        }
    };
    return (
        <>
            <Layout>
                {isLoading ?
                    <div id="spinner" className={`container`}>
                        <div className={`loading`}></div>
                    </div>
                    :

                    <div className={classes.AboutUs}>
                        <div className={classes.AboutUsHeader}>
                            <div className={classes.AboutUsHeaderTop}>
                                <div className={classes.AboutUsHeaderTopLeft}>

                                    <div className={classes.KnowUsHead}>
                                        <img src={line} alt="nextcircle" />
                                        <h1>know about us</h1>
                                    </div>
                                    <h2>
                                        We are a <br className={classes.break} />non-governmental organization
                                    </h2>
                                </div>
                                <div className={classes.AboutUsHeaderTopRight}>
                                    <h1>NEXT CIRCLE RELIEF & REHABILITATION FOUNDATION </h1>
                                    <h2>
                                        Next Circle strives to empower
                                        individuals to overcome challenges,
                                        rebuild their lives, and contribute
                                        positively to society. Together, we
                                        are working towards a future where
                                        everyone has the opportunity to
                                        thrive.

                                    </h2>

                                </div>
                            </div>

                            <div className={classes.AboutUsIMG} >
                                <img src={pictures[imageIndex]}
                                    alt={`Next circle ${imageIndex + 1}`}
                                    className={`${classes.fadeInOut} ${fade ? classes.fadeOut : ''}`}
                                />
                            </div>

                        </div>
                        <div className={classes.AboutUsTopic}>
                            <div className={classes.AboutUsMV}>
                                <div className={classes.AboutUsMission}>
                                    <h1>
                                        mission
                                    </h1>
                                    <h2>
                                        Our mission is to empower
                                        individuals and communities
                                        for a better, more harmonious
                                        world through innovation,
                                        education, and sustainability.
                                    </h2>
                                    <h3>
                                        Our mission is to empower individuals
                                        and communities by fostering growth,
                                        providing opportunities for redemption,
                                        and optimizing functionality. We
                                        strive to create a better world
                                        through innovative solutions, education,
                                        and sustainable practices, ensuring
                                        that every person can reach their
                                        full potential and contribute to
                                        a more prosperous and harmonious
                                        global society.
                                    </h3>
                                </div>
                                <div className={classes.AboutUsVision}>
                                    <h1>
                                        vision
                                    </h1>
                                    <h2>
                                        To create a better world with endless
                                        opportunities for absolution, growth
                                        and functionality.
                                    </h2>
                                    <h3>
                                        The vision is to create a better world
                                        with limitless opportunities for growth,
                                        forgiveness, and improved functionality.
                                        It envisions a place where individuals
                                        and communities can thrive, make amends
                                        for past mistakes, and develop in a
                                        functional and efficient environment.
                                        This aspiration encapsulates a commitment
                                        to fostering positive change on a broad scale.
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div id='#team' className={classes.Meet}>
                            <div className={classes.MeetTop}>
                                <h1>Meet Our Team</h1>
                                <h2>At Next Circle, our strength
                                    lies in the passion and commitment
                                    of our diverse team. Comprising
                                    individuals with a shared vision
                                    for positive change,
                                    Get to know the faces behind our mission:</h2>
                            </div>

                            <div className={classes.MeetTeam}>
                                <div className={classes.MeetTeamMembers}>
                                    <div className={classes.Members}>
                                        <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/team1_d7jrcg.jpg' alt="nextcircle" />
                                        <h1>Icent Francis</h1>
                                        <h2>Executive Chairman, Cofounder</h2>

                                        <h2>
                                            Icent Francis is a philanthropist, serial entrepreneur and entertainment executive.
                                            Icent holds a bachelor's degree in Logistics and Transportation Management,
                                            and in Sociology from ESGT Benin University Benin Republic,
                                            and University of Lagos Nigeria respectively. He has previously volunteered
                                            for several humanitarian projects. Icent is the Chairman, CEO of Wheels Gong
                                            Nigeria Limited, his project on Youths Development and Empowerment Program via
                                            entertainment in 2016 gained the government's recognition and was co-sponsored
                                            by Lagos State House of Assembly, Nigeria.
                                            Icent's immerse commitment and passion towards humanity and economic development
                                            has fueled his vision to eliminate destitution and recidivism in Nigeria, by extension
                                            Africa and the world at large, through creating opportunities for vocational skill training,
                                            rehabilitation, financial support, and providing equipment to skilled professionals through
                                            Next Circle Relief and Rehabilitation Foundation (NCRRF).
                                        </h2>
                                    </div>
                                    <div className={classes.Members}>
                                        <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/team3_xutauz.jpg' alt="nextcircle" />
                                        <h1>Popoola Olubunmi
                                        </h1>
                                        <h2>Cofounder, CEO</h2>
                                        <h2>Olubunmi Popoola is a philanthropist, human resource
                                            executive and has passionately volunteered for several
                                            NGOs for causes catering to welfare of women and children
                                            before cofounding Next Circle.
                                            She possesses a B.sc in Criminology and Security Studies
                                            and holds an MBA in Human Resources Management. Bunmi's
                                            goal to change the culture of victim blaming and victim
                                            shaming in Africa by empowering and converting victims
                                            to survivors through vocational training, therapy, access
                                            to business financing, and professional mentoring, all
                                            fueled her contribution in the formation of Next Circle
                                            Relief and Rehabilitation Foundation (NCRRF).
                                            Her Independent Single Mum (ISM) Initiative in 2017 provided
                                            therapy to 100+ single mums in Lagos, Nigeria, helped them
                                            to upskill in various proffessions, and helped them scale up
                                            their businesses and careers.
                                            She believes labelling is a major contributor to crime rate,
                                            hence her cause to battle recidivism by providing alternative
                                            survival means for formerly convicted persons, and stigmatized
                                            victims by proper reintegration into society and guidance
                                            towards opportunities for economic productivity.
                                        </h2>

                                    </div>
                                    <div className={classes.Members}>
                                        <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/team2_lyzvb8.jpg' alt="nextcircle" />
                                        <h1>David Edwin</h1>
                                        <h2>Communications Director</h2>

                                        <h2>
                                            David Chinedu Edwin, popularly known as David E, is
                                            an award wining gospel music artist, and broadcaster.
                                            He is the C.E.O of iDave Media Group, a media outfit with
                                            New Wine Radio and others.
                                            A graduate in Mass Communication with the Nigerian
                                            Institute Of Journalism Lagos Nigeria.
                                            David E, is a worship leader at The Fountain of Life
                                            Church Lagos Nigeria. With a gospel album (Something About You)
                                            to his credit. With multiple nominations at the
                                            Prayze Factor Awards Season 13 Atlanta USA.
                                            David E is a gentleman, lover of God and humanity
                                            and a goal getting humble leader.
                                            With the decay in cultural and societal values,
                                            David's goal is to lend his voice and his all to
                                            Next Circle Foundation's mission and vision, as
                                            spreads the gospel of hope by combating crime rates
                                            through love and care and also by promoting topmost
                                            socially acceptable moral values.
                                        </h2>
                                    </div>
                                    <div className={classes.Members}>
                                        <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/team4_f6m7kq.jpg' alt="nextcircle" />
                                        <h1> Lazarus Ojeabu Basil</h1>
                                        <h2>Trustee, Counsellor</h2>

                                        <h2>

                                            A graduate of philosophy university
                                            of Lagos Nigeria, Diploma in Information
                                            Systems, Information Aptech worldwide,
                                            Santa Clara California, Diploma in theology
                                            West Africa Bible College Lagos Nigeria.
                                            CEO Fyreflyz Concept, an event outfit and a
                                            preacher of the Gospel with Divine Palace
                                            Church International.

                                            Lazarus is a goal getter, a humble
                                            and gentleman with a great zeal to
                                            influence the event industry and impact
                                            humanity posivitily. He his focused on impacting
                                            lives through the gospel of love in Jesus Christ,
                                            serving as an influencer and in all capacity with
                                            Next Circle Foundation vision and its mission to make
                                            the society a better place for existence
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="contactus" className={classes.ContactUs}>
                            <div className={classes.ContactUsMain}>
                                <div className={classes.ContactUsMainLeft}>
                                    <div className={classes.ContactUsMainLeftTop}>
                                        <img src={line} alt="nextcircle" />
                                        <h1>Contact us</h1>
                                    </div>
                                    <h1 className={classes.ContactUsMainLeftText}>
                                        We'd love to hear from you
                                    </h1>
                                    <h2 className={classes.ContactUsMainLeftTextTwo}>
                                        Have any question in mind or want
                                        to enquire? Please feel free to
                                        contact us through the form or
                                        the following details.
                                    </h2>
                                </div>
                                <div className={classes.ContactUsMainRight}>
                                    <div className={classes.ContactUsMainRightText}>
                                        <h1>
                                            Let's talk!
                                        </h1>
                                        <h2>+234 812 6672 220</h2>
                                        <a href='mailto:info@nextcircle.org'>info@nextcircle.org</a>
                                    </div>
                                    <div className={classes.ContactUsMainRightText}>
                                        <h1>
                                            Head Office
                                        </h1>
                                        <h2>7, Samuel Jinadu Street,
                                            Off Markaz Road,
                                            Agege, Lagos State, Nigeria</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form ref={form} onSubmit={onSubmit}>
                            <div className={classes.FormSection}>
                                <div className={`${classes.FormTop} ${classes.Extra}`}
                                >
                                    <div className={classes.FormTopLeft}>
                                        <h1>First Name *</h1>
                                        <input
                                            name="first_name"
                                            type='text'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className={classes.FormTopRight}>
                                        <h1>Last name *</h1>
                                        <input
                                            name="last_name"
                                            type='text'
                                            onChange={handleChange}
                                            required

                                        />
                                    </div>
                                </div>

                                <div className={classes.FormTop}>
                                    <div className={classes.FormTopLeft}>
                                        <h1>Country Code *</h1>

                                        <select name="countrySelect"
                                            defaultValue={''}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option
                                                disabled
                                                defaultValue=''
                                                value={toSend.countrySelect}
                                                onChange={handleChange}

                                            >Select a country</option>
                                            {countries.map(country => (
                                                <option key={country.code}
                                                    value={country.code}>
                                                    {country.dial_code} - {country.name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>

                                    <div className={classes.FormTopRight}>
                                        <h1>Phone Number *</h1>

                                        <input
                                            name="phone_number"
                                            type='number'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className={classes.FormTop}>

                                    <div className={classes.FormTopRight}>
                                        <h1>Subject</h1>
                                        <select id="selectInput"
                                            name="subject_title"
                                            onChange={handleChange}
                                            required
                                            defaultValue=''
                                        >
                                            <option disabled value="">Select an Option</option>
                                            <option value="Donation">Donate</option>
                                            <option value="Volunteering">Volunteer</option>
                                            <option value="Aid">Request Aid</option>
                                            <option value="Join Our Programme">Join Our Programmes</option>
                                            <option value="Partner With Us">Partner With Us</option>
                                            <option value="Counselling">Counselling</option>
                                            <option value="Intervention">Request Intervention</option>
                                            <option value="Invite Us">Invite Us</option>
                                            <option value="Request A Call">Request A Call</option>
                                            <option value="Pray With Someone">Pray With Someone</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>

                                    {toSend.subject_title === 'other' ? (
                                        <div className={classes.FormTopLeft}>
                                            <h1>Other</h1>
                                            <input
                                                name="subject_title_other"
                                                type='text'
                                                onChange={handleChange}
                                            />
                                        </div>
                                    ) : (
                                        null
                                    )}
                                </div>
                                <div className={classes.FormTop}>
                                    <div className={classes.FormTopLeft}>
                                        <h1>Email *</h1>
                                        <input
                                            name="email"
                                            type='email'
                                            onChange={handleChange}
                                            required
                                            className={classes.emmail}
                                        />
                                    </div>
                                </div>
                                <div className={classes.FormSubject}>
                                    <textarea
                                        name='message'
                                        placeholder='Type your Message'
                                        required
                                        onChange={handleChange}
                                    />
                                </div>

                                {loading === true ? (
                                    <div className={classes.FormButton}>
                                        <button disabled type='submit'>Submitting....</button>
                                    </div>
                                ) : (
                                    <div className={classes.FormButton}>
                                        <button type='submit'>Send Message</button>
                                    </div>
                                )}
                            </div>
                        </form>
                        <div className={classes.map}>
                            <iframe
                                title='map'
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.208649078634!2d3.3122922108896447!3d6.6209833219945144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b91120b1516e1%3A0x97c47684984f1fd3!2s7%20Samuel%20Jinadu%20St%2C%20Papa%20Ashafa%20102212%2C%20Lagos!5e0!3m2!1sen!2sng!4v1700495320741!5m2!1sen!2sng"
                                width="1920" height="448"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>}
            </Layout>
        </>
    );
};

export default AboutUs;
