import React from 'react'
import Layout from '../../Layout/Layout'
import classes from './Blog.module.css'
import line from '../../assets/images/Line.png'

const Blog = () => {
    return (
        <>
            <Layout>
                <div className={classes.Blog}>
                    <div className={classes.ContactUsMainLeftTop}>
                        <img src={line} alt="nextcircle" />
                        <h1>blog news</h1>
                    </div>
                    <div className={classes.BlogText}>
                        <h1>OUR BLOG IS COMING SOON...</h1>
                    </div>

                </div>
                <section className={classes.Contributions}>
                    <div className={classes.Contributionscard}>
                        <div className={classes.ContributionscardText}>
                            <h1>We empower marginalized individuals fostering economic productivity and community reintegration</h1>
                            <div className={classes.Contributionsbtn}>
                                <button className={classes.Contributionsbtn1}>Join as a Volunteer</button>
                                <button className={classes.Contributionsbtn2}>Donate</button>
                            </div>
                        </div>
                    </div>
                </section>

            </Layout>
        </>
    )
}

export default Blog
