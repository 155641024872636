import React from 'react'
import classes from './Navbar.module.css'
import { useState, useEffect } from 'react';
import * as data from '../../data'
import { Link, useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';

const Navbar = () => {
    const navigate = useNavigate();

    const [, setActiveTabId] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);

    useEffect(() => {
        setCurrentPage(
            sessionStorage.getItem('currentPage') ? Number(sessionStorage.getItem('currentPage')) : 0
        );
        setActiveTabId(
            sessionStorage.getItem('currentPage') ? Number(sessionStorage.getItem('currentPage')) : 0
        );
    }, []);
    const navitem = data.navTitles
    const handleClickTabLink = (id) => {
        setActiveTabId(id);
        setCurrentPage(id);
        sessionStorage.setItem('currentPage', id);
        sessionStorage.setItem('activeTab', id);
    }
    const goHome = () => {
        sessionStorage.setItem('currentPage', 0);
        navigate('/');
    };

    const doDonate = () => {
        const mailtoLink = 'mailto:info@nextcircle.org?subject=I%20Want%20To%20Donate';
        window.location.href = mailtoLink;
    }
    return (
        <>
            <nav className={classes.NavbarMain}>
                <div className={classes.NavbarLogo} >
                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1702718528/Untitled_design__2_-removebg-preview_oznocp.png' alt="nextcircle" onClick={goHome} />
                </div>
                <div className={classes.NavbarLinks}>
                    <ul>
                        {navitem.map((items) => {
                            const { id, title, link } = items;
                            return (
                                <li key={id} onClick={() => handleClickTabLink(id)} className={classes.link}>
                                    <Link to={`${link}`} className={`${currentPage === id ? classes.ActiveTab : ''}`}>
                                        {title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={classes.NavbarBtn}>
                    <button onClick={doDonate}>
                        Donate
                    </button>
                </div>
            </nav>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <div className={classes.NavbarLogo} >
                            <img src='https://res.cloudinary.com/naijakids/image/upload/v1702718528/Untitled_design__2_-removebg-preview_oznocp.png' alt="nextcircle" onClick={goHome} />
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={classes.NavbarLinks}>
                        <ul>
                            {navitem.map((items) => {
                                const { id, title, link } = items;
                                return (
                                    <li key={id} onClick={() => handleClickTabLink(id)} className={classes.link}>
                                        <Link to={`${link}`} className={`${currentPage === id ? classes.ActiveTab : ''}`}>
                                            {title}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div className={classes.MobileNav}>
                <div className={classes.NavbarLogo} >
                    <img src='https://res.cloudinary.com/naijakids/image/upload/v1702718528/Untitled_design__2_-removebg-preview_oznocp.png' alt="nextcircle" />
                </div>
                <Hamburger onClick={handleShow} />
            </div>
        </>

    )
}

export default Navbar
