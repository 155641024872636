import React from 'react';
import classes from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <div className={classes.FooterLogo}>
                <img src='https://res.cloudinary.com/naijakids/image/upload/v1702718528/Untitled_design__2_-removebg-preview_oznocp.png' alt="nextcircle" />
            </div>
            <div className={classes.FooterLinks}>
                <div className={classes.FooterLink}>
                    <ul>
                        <Link to={'/'}>
                            <li>
                                Home
                            </li>
                        </Link>
                        <Link to='/about-us'>
                            <li>About Us</li>
                        </Link>
                        <Link to='/about-us'>
                            <li>Contact</li>

                        </Link>
                        <Link to='/'>
                            <li>What we do</li>
                        </Link>
                        <Link to='/about-us'>
                            <li>Team</li>
                        </Link>
                    </ul>
                </div>
                <div className={classes.FooterLink}>
                    <ul>
                        {/* <li>More</li> */}
                        {/* <li>Project</li> */}
                        {/* <li>Event</li> */}
                        <Link to='mailto:info@nextcircle.org'>
                            <li>Donate</li>
                        </Link>
                        <Link to='/blog'>
                            <li>Blog</li>
                        </Link>
                    </ul>
                </div>
                <div className={classes.FooterLink}>
                    <ul>
                        {/* <li>Connect</li> */}
                        <Link to={'https://web.facebook.com/people/Next-Circle-Foundation/100071515163185/'}>
                            <li>Facebook</li>
                        </Link>
                        <Link to={'https://www.instagram.com/nextcirclefoundation/'}>
                            <li>Instagram</li>
                        </Link>
                        {/* <li>LinkedIn</li> */}
                        {/* <li>Twitter</li> */}
                    </ul>
                </div>
            </div>
            <div className={classes.FooterSubscribe}>
                <div>
                    <h1>Subscribe to get latest updates</h1>
                    <div className={classes.btnGroup}>
                        <input type="text" placeholder='Your email' />
                        <button>Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
